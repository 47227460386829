/* https://tailwindui.com/components/marketing/elements/headers */
import React, {Fragment} from "react";
import logo from "../assets/images/Pirmann_Media_Logo.svg";
import {Popover, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  MenuIcon,
  PhoneIcon,
  ShieldCheckIcon,
  SupportIcon,
  XIcon,
} from "@heroicons/react/outline";
import {Link} from "react-router-dom";

const beratungCTA = "Kontaktieren Sie uns";
const beratungLink = "mailto:johannes.pirmann@pirmannmedia.com"
const beratungLinkOld = "https://calendly.com/johannes-pirmann/60-min";

const services = [
    {
        nameOfService: "Webseite",
        description: "Die Webseite f\u00fcr ihr Business!",
        href: "#",
        icon: ChartBarIcon,
    },
    {
        nameOfService: "Online Shop",
        description: "Der Online Shop f\u00fcr ihr Business!",
        href: "#",
        icon: ChartBarIcon,
    },
    {
        nameOfService: "Beratung",
        description: "Wir helfen Ihnen bei technischen Entscheidungen.",
        href: "#",
        icon: ChartBarIcon,
    },
    {
        nameOfService: "Prozess automatisierung",
        description: "Wir helfen Ihnen wiederkehrende Prozesse zu automatisieren.",
        href: "#",
        icon: ChartBarIcon,
    },
];

const callsToAction = [
    {
        nameOfCTA: "Beratung buchen",
        href: "https://calendly.com/johannes-pirmann/60-min",
        icon: PhoneIcon,
    },
];

const resources = [
    {
        nameOfResource: "Help Center",
        description:
            "Get all of your questions answered in our forums or contact support.",
        href: "#",
        icon: SupportIcon,
    },
    {
        nameOfResource: "Guides",
        description:
            "Learn how to maximize our platform to get the most out of it.",
        href: "#",
        icon: BookmarkAltIcon,
    },
    {
        nameOfResource: "Events",
        description:
            "See what meet-ups and other events we might be planning near you.",
        href: "#",
        icon: CalendarIcon,
    },
    {
        nameOfResource: "Security",
        description: "Understand how we take your privacy seriously.",
        href: "#",
        icon: ShieldCheckIcon,
    },
];
const recentPosts = [
    {id: 1, nameOfPost: "Boost your conversion rate", href: "#"},
    {
        id: 2,
        nameOfPost:
            "How to use search engine optimization to drive traffic to your site",
        href: "#",
    },
    {id: 3, nameOfPost: "Improve your customer experience", href: "#"},
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class NavBar extends React.Component {
    render() {
        return (
            <nav className="relative bg-white">
                <Popover className="relative bg-white">
                    {({open}) => (
                        <>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6">
                                <div
                                    className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
                                    <div className="flex justify-start lg:w-0 lg:flex-1">
                                        <Link to="/">
                                            <span className="sr-only">Pirmann Media</span>
                                            <img className="h-8 w-auto sm:h-10" src={logo} alt=""/>
                                        </Link>
                                    </div>
                                    <div className="-mr-2 -my-2 md:hidden">
                                        <Popover.Button
                                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Open menu</span>
                                            <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                                        </Popover.Button>
                                    </div>

                                    <Popover.Group as="nav" className="hidden md:flex space-x-10">
                                      {/* This is the Service menu entry
                                        <Popover className="relative">
                                            {({open}) => (
                                                <>
                                                    <Popover.Button
                                                        className={classNames(
                                                            open ? "text-gray-900" : "text-gray-500",
                                                            "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        )}
                                                    >
                                                        <span>Services</span>
                                                        <ChevronDownIcon
                                                            className={classNames(
                                                                open ? "text-gray-600" : "text-gray-400",
                                                                "ml-2 h-5 w-5 group-hover:text-gray-500"
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </Popover.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="opacity-0 translate-y-1"
                                                        enterTo="opacity-100 translate-y-0"
                                                        leave="transition ease-in duration-150"
                                                        leaveFrom="opacity-100 translate-y-0"
                                                        leaveTo="opacity-0 translate-y-1"
                                                    >
                                                        <Popover.Panel
                                                            static
                                                            className="absolute z-20 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                                        >
                                                            <div
                                                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                                <div
                                                                    className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                                    {services.map((item) => (
                                                                        <a
                                                                            key={item.nameOfService}
                                                                            href={item.href}
                                                                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                                        >
                                                                            <item.icon
                                                                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                                                aria-hidden="true"
                                                                            />
                                                                            <div className="ml-4">
                                                                                <p className="text-base font-medium text-gray-900">
                                                                                    {item.nameOfService}
                                                                                </p>
                                                                                <p className="mt-1 text-sm text-gray-500">
                                                                                    {item.description}
                                                                                </p>
                                                                            </div>
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                                <div
                                                                    className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                                                    {callsToAction.map((item) => (
                                                                        <div
                                                                            key={item.nameOfCTA}
                                                                            className="flow-root"
                                                                        >
                                                                            <a
                                                                                href={item.href}
                                                                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                                                            >
                                                                                <item.icon
                                                                                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <span className="ml-3">
                                                                                  {item.nameOfCTA}
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Popover.Panel>
                                                    </Transition>
                                                </>
                                            )}
                                        </Popover> */}
                                        {/* This is a Link to the pricing
                                        <a
                                          href="#"
                                          className="text-base font-medium text-gray-500 hover:text-gray-900"
                                        >
                                          Pricing
                                        </a>*/}
                                      {/* {/* This is a Link to the blog
                                        <a
                                            href="https://blog.pirmannmedia.com"
                                            target="_blank"
                                            className="text-base font-medium text-gray-500 hover:text-gray-900"
                                        >
                                            Blog
                                        </a>
                                        */}
                                        {/*
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? "text-gray-900" : "text-gray-500",
                    "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  )}
                >
                  <span>More</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-gray-600" : "text-gray-400",
                      "ml-2 h-5 w-5 group-hover:text-gray-500"
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel
                    static
                    className="absolute z-20 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
                  >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {resources.map((item) => (
                          <a
                            key={item.nameOfResource}
                            href={item.href}
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                          >
                            <item.icon
                              className="flex-shrink-0 h-6 w-6 text-indigo-600"
                              aria-hidden="true"
                            />
                            <div className="ml-4">
                              <p className="text-base font-medium text-gray-900">
                                {item.nameOfResource}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {item.description}
                              </p>
                            </div>
                          </a>
                        ))}
                      </div>
                      <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                        <div>
                          <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">
                            Recent Posts
                          </h3>
                          <ul className="mt-4 space-y-4">
                            {recentPosts.map((post) => (
                              <li key={post.id} className="text-base truncate">
                                <a
                                  href={post.href}
                                  className="font-medium text-gray-900 hover:text-gray-700"
                                >
                                  {post.nameOfPost}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="mt-5 text-sm">
                          <a
                            href="#"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            {" "}
                            View all posts{" "}
                            <span aria-hidden="true">&rarr;</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>*/}
                                    </Popover.Group>
                                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                                        {/*<a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                  Sign in
                            </a>*/}
                                        <a
                                            href={beratungLink}
                                            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                        >
                                            {beratungCTA}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <Transition
                                show={open}
                                as={Fragment}
                                enter="duration-200 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel
                                    focus
                                    static
                                    className="absolute top-0 z-20 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                                >
                                    <div
                                        className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                        <div className="pt-5 pb-6 px-5">
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <img
                                                        className="h-8 w-auto"
                                                        src={logo}
                                                        alt="Workflow"
                                                    />
                                                </div>
                                                <div className="-mr-2">
                                                    <Popover.Button
                                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                        <span className="sr-only">Close menu</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </Popover.Button>
                                                </div>
                                            </div>
                                          {/*
                                            <div className="mt-6">
                                                <nav className="grid gap-y-8">
                                                    {services.map((item) => (
                                                        <a
                                                            key={item.nameOfService}
                                                            href={item.href}
                                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                                        >
                                                            <item.icon
                                                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                            <span className="ml-3 text-base font-medium text-gray-900">
                                                              {item.nameOfService}
                                                            </span>
                                                        </a>
                                                    ))}
                                                </nav>
                                            </div>
                                            */}
                                        </div>
                                        <div className="py-6 px-5 space-y-6">
                                            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                                {/*
                                                <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                  Pricing
                                                </a>*/}

                                                <a
                                                    href="https://blog.pirmannmedia.com"
                                                    target="_blank"
                                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                                >
                                                    Blog
                                                </a>
                                                {/*
                    {resources.map((item) => (
                      <a
                        key={item.nameOfResource}
                        href={item.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {item.nameOfResource}
                      </a>
                    ))}
                    */}
                                            </div>
                                            <div>
                                                <a
                                                    href={beratungLink}
                                                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                                >
                                                    {beratungCTA}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </nav>
        );
    }
}

export default NavBar;
