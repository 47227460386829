import React from "react";
import BergLogo from '../assets/images/clients/Kaspar-Berg-Logo.png'
import FuCoLogo2 from '../assets/images/clients/Full-Control-Logo-4.png'
import WinzerhofPirmannLogo from '../assets/images/clients/Winzerhof-Pirmann-Logo.svg'

const clients = [
  {
    clientName: "Full Control GmbH",
    logo: FuCoLogo2,
  },
  {
    clientName: "Winzerhof Pirmann",
    logo: WinzerhofPirmannLogo,
  },
  {
    clientName: "Kaspar Berg GmbH",
    logo: BergLogo,
  },

];

class Clients extends React.Component {
  render() {
    return (
      <div className="bg-white overflow-hidden ">
        <div className="pt-10 md:py-10 bg-white">
          <h1 class="text-3xl md:text-5xl text-gray-900 text-center font-semibold mx-auto">
            Unsere Kunden:
          </h1>
        </div>
        <div className="flex bg-white justify-center pt-5 pb-10 md:space-x-14 flex-col md:flex-row">
          {clients.map((client) => (
            <div className="flex flex-col justify-center items-center ">
              <img
                className="h-8 w-auto  my-2 sm:h-10 md:my-0"
                src={client.logo}
                alt={client.clientName}
              />{" "}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default Clients;
