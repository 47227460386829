import React, { Component } from "react";
import {Link} from "react-router-dom";

const legalhref = [
  {
    nameOfLegal: "Impressum",
    href: "/impressum",
  },
  {
    nameOfLegal: "Datenschutzerkl\u00e4rung",
    href: "/datenschutzerklaerung",
  },
];
let copyright = "\u00a9";

export default class Footer extends Component {
  render() {
    return (
      <div className="h-36  bottom-0 w-full">
        <div className="grid grid-col-1 md:grid-cols-3  place-items-center py-5 md:space-x-6 bg-gray-900 text-white min-h-full">
          <div className="order-last py-2.5 md:order-first md:py-0">
            <h1>
              Pirmann Media{" "}
              <span className="text-gray-500">
                {" "}
                {copyright} {new Date().getFullYear()}
              </span>
            </h1>
          </div>
          <div className='justify-center flex py-2.5 space-x-6 md:py-5'>
            {legalhref.map((legal) => (
              <div>
                <Link to={legal.href}>
                  <h1>{legal.nameOfLegal}</h1>
                </Link>
              </div>
            ))}
          </div>
          <div className="order-first py-2.5 md:order-3 md:py-0">
              <a href='https://blog.pirmannmedia.com' target='_blank' rel="noreferrer">
                  <h1>Blog</h1>
              </a>
          </div>
        </div>
      </div>
    );
  }
}
