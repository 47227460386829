import React, { Component } from "react";
import { PresentationChartLineIcon, ShoppingBagIcon, ClipboardCheckIcon, TerminalIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Shopify einrichtung & entwicklung',
    description:
      'Wir unterstützen Sie bei der Einrichtung Ihres Shopify Shops, bei der Entwicklung eines Themes. Wir supporten Sie auch bei kleineren Fragen und Problemen rund um Ihren Shop.',
    icon: ShoppingBagIcon,
  },
  {
    name: 'Webentwicklung',
    description:
      'Wir bauen Ihre Webseite oder Webanwendung. Benötigt Ihr Entwickler Team kurzfristig Unterstützung? Wir unterstützen Sie gerne.',
    icon: TerminalIcon,
  },
  {
    name: 'Projekt Management',
    description:
      'Benötigen Sie Hilfe bei der Planung, Koordination, und Ausführung ihres Projektes? ' +
        'Mit unserer mehrjährigen Erfahrung im Projektmanagement bei kleinen Startups und KMUs unterstützen wir Sie gerne!',
    icon: ClipboardCheckIcon,
  },
  {
    name: 'Beratung',
    description:
      'Wir helfen Ihnen Ihre Fragen zu Webseiten, Webanwendungen, und Online-Shops zu klären.',
    icon: PresentationChartLineIcon,
  },
]

export default class Features extends Component {
  render() {
    return (
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
              Leistungen
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Unsere Leistungen
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Unser Herz schlägt für die Webentwicklung, daher haben wir unser Leistungsspektrum stark reduziert, um diese Leidenschaft wiederzuspiegeln.
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    );
  }
}
