import React, { Component } from "react";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";

const beratungCTA = "Beratung buchen";
const beratungLink = "https://calendly.com/johannes-pirmann/60-min";

export default class CtaButton extends Component {
  render() {
    return (
        <div className="flex mt-5 sm:mt-8 sm:flex justify-center">
        <div className="rounded-md shadow ">
          <a
            href={beratungLink}
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-3 md:text-lg md:px-8"
          >
            {beratungCTA}{" "}
            <ArrowNarrowRightIcon className="h-6 w-6 pl-1" aria-hidden="true" />
          </a>
        </div>
      </div>
    );
  }
}
