import NavBar from "./components/navigation";
import Footer from "./components/footer";
import Routes from "./pages/Main";

function App() {
  return (
    <div>
      <NavBar />
        <Routes />
      <Footer />
    </div>
  );
}

export default App;
