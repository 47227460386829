import HeroImage from "../components/hero-with-image";
import Clients from "../components/clients";
import Cta from "../components/cta";
import Features from "../components/features";
import CtaButton from "../components/ctaButton";

function MainPage() {
    return (
        <div>
            <HeroImage />
            <Clients />
            {/*<CtaButton />*/}
            <Features />
            <Cta />
        </div>
    );
}

export default MainPage;