import React from 'react';
import { Routes, Route } from 'react-router-dom';

import MainPage from "./MainPage";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutzerklaerung";

const Main = () => {
    return (
        <Routes> {/* The Switch decides which component to show based on the current URL.*/}
            <Route path='/' element={<MainPage />}></Route>
            <Route path='/impressum' element={<Impressum />}></Route>
            <Route path='/datenschutzerklaerung' element={<Datenschutz />}></Route>

        </Routes>
    );
}

export default Main;